// src/App.js
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import {
  HashRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Home from "./component/Home";
import Survey from "./component/survey/Survey";
import Start from "./component/start/Start";
import Privacy from "./component/start/Privacy";
import Terms from "./component/start/Terms";
import Translate from "./translate/Translate";
import TestChat from "./component/chat/TestChat";
import ResearchAdmin from "./component/research-admin/ResearchAdmin";
import Store from "./store/Store";
import DailyMission from "./component/mission/DailyMission";
import Feedback from "./component/mission/feedback/Feedback";

import DailyLifestyle from "./component/mission/DailyLifestyle-02";
import MyProfile from "./component/profile/MyProfile";
import DailyWeigh from "./component/mission/sub/Daily-01-Weigh";
import EarnedCoin from "./component/mission/EarnedCoin";
import { RecoilRoot, useRecoilState } from "recoil";
import WeightHistory from "./component/mission/fatloss/WeightHistory";
import DailyPredictFatLoss from "./component/mission/sub/Daily-Predict-FatLoss";
import DailyHabits from "./component/mission/sub/Daily-03-Habits";
import Daily04Workout from "./component/mission/sub/Daily-04-Workout";
import DailyMore from "./component/mission/DailyMore";
import Chatbot01 from "./component/chat/Chatbot01";
import MealSnap from "./component/meal/MealSnap";
import MealGallery from "./component/meal/MealGallery";
import NextLevel from "./component/mission/level/NextLevel";
import CustomAppBar from "./component/avatar/CustomAppBar";
import SignupLogin from "./component/avatar/signup-login/signup-login";
import Logout from "./component/avatar/signup-login/logout";
import Tips from "./component/chat/Tips";

import TestCustomUser from "./component/avatar/signup-login/ztest-custom-user";
import CalorieHistory from "./component/mission/fatloss/CalorieHistory";
import MealCrop from "./component/meal/MealCrop";
import PumpData from "./component/avatar/signup-login/zpump-data";
import ResearchAdminOffline from "./component/research-admin/ResearchAdminOffline";
import AppointmentOffline from "./component/research-admin/appointment/AppointmentOffline";
import AppointmentBooking from "./component/mission/appointment/AppointmentBooking";
import UpLevelMedal from "./component/mission/UpLevelMedal";
import TrackBMI from "./component/mission/sub/TrackBMI";
import { ProtectedRoute } from "./route/ProtectedRoute";
import DailyCheckin from "./component/mission/sub/Daily-00-Checkin";


import { versionAtom } from "./atom/version/version-atom";
import VxVersionService from "./api/VxVersionService";

const App = () => {
  const [version, setVersion] = useRecoilState(versionAtom);
  const [isWebpushrInitialized, setIsWebpushrInitialized] = useState(false);
  const [userWebPushrToken, setUserWebPushrToken] = useState(null);
  
  const fetchAndCompareVersion = async () => {
    const fetchedVersion = await VxVersionService.fetchVersion();
    if (fetchedVersion && fetchedVersion !== version) {
      console.log(`Current version: ${version}, Latest version: ${fetchedVersion}`);
      setVersion(fetchedVersion);
    }
  };

  useEffect(() => {
    const initializeWebpushr = async () => {
      if (!isWebpushrInitialized) {
        await (function(w,d, s, id) {
          if(typeof(w.webpushr) !== 'undefined') return; 
          w.webpushr = w.webpushr || function(){(w.webpushr.q = w.webpushr.q || []).push(arguments)}; 
          var js, fjs = d.getElementsByTagName(s)[0];
          js = d.createElement(s); js.id = id;
          js.async = 1;
          js.src = "https://cdn.webpushr.com/app.min.js";
          fjs.parentNode.insertBefore(js, fjs);
        }(window,document, 'script', 'webpushr-jssdk'));

        window.webpushr('setup',{
          sw: '/webpushr-sw.js',
          key: `${process.env.REACT_APP_WEBPUSHR_SITE_KEY}`
        });
        
        setIsWebpushrInitialized(true);
      }
    };

    initializeWebpushr();

    const intervalId = setInterval(() => {
      fetchAndCompareVersion(); // Fetch every 30 minutes
    }, 30 * 60 * 1000); // 30 minutes in milliseconds

    return () => {
      clearInterval(intervalId);
    }; // Cleanup on unmount
  }, [version, setVersion, setIsWebpushrInitialized]);


  useEffect(() => {
    if (isWebpushrInitialized) {
      // Check if the user is subscribed and get the token
      window.webpushr('attributes', function(attributes) {
        if (attributes && attributes.endpoint) {
          // The user is subscribed
          // The token is the last part of the endpoint URL
          const token = attributes.endpoint.split('/').pop();
          setUserWebPushrToken(token);
          console.log('User token:', token);
        } else {
          console.log('User is not subscribed');
        }
      });
    }
  }, [isWebpushrInitialized, setUserWebPushrToken]);

  const promptForNotificationPermission = () => {
    window.webpushr('subscribe')
      .then(token => {
        if (token) {
          setUserWebPushrToken(token);
          console.log('User subscribed. Token:', token);
        }
      })
      .catch(error => {
        console.error('Subscription failed:', error);
      });
  };

  const [recheckIsCompleteSurvey, setRecheckIsCompleteSurvey] = useState(0);
  const [isAllowedBackHome, setIsAllowedBackHome] = useState(false);
  const [playEarnCoin, setPlayEarnCoin] = useState(false);
  const [coinsEarned, setCoinsEarned] = useState(0);

  const pathname = window.location.pathname;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <div style={{ flex: 1 }}>
        <Tips />
        <EarnedCoin />
        <UpLevelMedal />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/start" element={<Start />} />
          <Route
            path="/survey"
            element={
              <ProtectedRoute>
                <Survey
                  setIsAllowedBackHome={setIsAllowedBackHome}
                  recheckIsCompleteSurvey={recheckIsCompleteSurvey}
                />
              </ProtectedRoute>
            }
          />
          <Route path="/privacy" element={<Privacy />} />
          <Route
            path="/terms"
            element={<Terms setIsAllowedBackHome={setIsAllowedBackHome} />}
          />
          <Route path="/translate" element={<Translate />} />
          <Route path="/daily" element={<DailyMission promptForNotificationPermission={promptForNotificationPermission} />} />
          <Route path="/lifestyle" element={<DailyLifestyle />} />
          <Route
            path="/testchat"
            element={
              /* api={api} */
              <TestChat setIsAllowedBackHome={setIsAllowedBackHome} />
            }
          />
          <Route path="/research-admin" element={<ResearchAdmin />} />
          <Route path="/research-admin-offline" element={<ResearchAdminOffline />} />
          <Route path="/myprofile" element={<MyProfile />} />
          <Route path="/weighing" element={<DailyWeigh />} />
          <Route path="/weight-history" element={<WeightHistory />} />
          <Route path="/fat-loss" element={<DailyPredictFatLoss />} />
          <Route path="/assess-habits" element={<DailyHabits />} />
          <Route path="/exercise" element={<Daily04Workout />} />
          <Route path="/daily-more" element={<DailyMore />} />
          <Route path="/chatbot" element={<Chatbot01 />} />
          <Route path="/mealsnap" element={<MealSnap />} />
          <Route path="/mealcrop" element={<MealCrop />} />
          <Route path="/gallery" element={<MealGallery />} />
          <Route path="/next-level" element={<NextLevel />} />
          <Route path="/signup-login" element={<SignupLogin />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/testcustom" element={<TestCustomUser />} />
          <Route path="/calorie" element={<CalorieHistory />} />
          <Route path="/pumpdata" element={<PumpData />} />
          <Route path="/appointment-offline" element={<AppointmentOffline />} />
          <Route path="/appointment-booking" element={<AppointmentBooking />} />
          <Route path="/track-bmi" element={<TrackBMI />} />
          <Route path="/daily-checkin" element={<DailyCheckin />} />
          <Route path="/feedback" element={<Feedback />} />
        </Routes>
      </div>
      <CustomAppBar />
    </div>
  );
};

const AppWrapper = () => (
  <Provider store={Store}>
    <Router>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </Router>
  </Provider>
);

export default AppWrapper;
