import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faFire } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getUserObj } from "../../../store/pouch/user-pouch";
import { getCaloriesByUserEmailAndDateTimeString } from "../../../store/pouch/calorie-pouch";
import { getMealsByUserEmailAndEpoch } from "../../../store/pouch/meal-pouch";
import { getStartOfDayISOStringZ, getEndOfDayISOStringZ } from "../../../util/Util";

const CalorieHistory = () => {
  const navigate = useNavigate();
  const [calorieGoal, setCalorieGoal] = useState(2000);
  const [caloriesConsumed, setCaloriesConsumed] = useState(0);
  const [caloriesBurned, setCaloriesBurned] = useState(0);
  const [userGenderState, setUserGenderState] = useState("");
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      const fetchUserProfileAndCalories = async () => {
        try {
          const userObj = await getUserObj("active");
          if (userObj) {
            const { userInitialWeightKg, userHeightCm, userAgeGroup, userGender, userEmail } = userObj;

            setUserGenderState(userGender);

            // Calculate BMR
            const ageGroupMap = {
              "≤20": 20, "21-30": 25, "31-40": 35, "41-50": 45, "51-60": 55, "≥61": 61
            };
            const numericAge = ageGroupMap[userAgeGroup] || 30;

            let bmr;
            if (userGender === "male") {
              bmr = 10 * userInitialWeightKg + 6.25 * userHeightCm - 5 * numericAge + 5;
            } else {
              bmr = 10 * userInitialWeightKg + 6.25 * userHeightCm - 5 * numericAge - 161;
            }

            setCalorieGoal(Math.round(bmr));

            // Fetch exercise data for today
            const startOfDay = getStartOfDayISOStringZ();
            const endOfDay = getEndOfDayISOStringZ();
            const exerciseData = await getCaloriesByUserEmailAndDateTimeString(
              userEmail,
              startOfDay,
              endOfDay,
              "exercise"
            );

            // Calculate total calories burned
            const totalCaloriesBurned = exerciseData.reduce((total, item) => total + Math.abs(item.calorieChange), 0);
            setCaloriesBurned(Math.round(totalCaloriesBurned));

            // Fetch meal data for today
            const startOfDayEpoch = new Date(startOfDay).getTime();
            const endOfDayEpoch = new Date(endOfDay).getTime();
            const mealData = await getMealsByUserEmailAndEpoch(
              userEmail,
              startOfDayEpoch,
              endOfDayEpoch
            );

            // Calculate total calories consumed
            const totalCaloriesConsumed = mealData.reduce((total, meal) => total + (meal.mealCalories || 0), 0);
            setCaloriesConsumed(Math.round(totalCaloriesConsumed));
          }
        } catch (error) {
          console.error("Error fetching user profile or calories:", error);
        }
      };

      fetchUserProfileAndCalories();
      
      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  // Calculate percentages
  const totalAllowedCalories = calorieGoal + Math.abs(caloriesBurned);
  const consumedPercentage = Math.min((caloriesConsumed / totalAllowedCalories) * 100, 100);
  const usedAllowancePercentage = ((caloriesConsumed / totalAllowedCalories) * 100).toFixed(1);

  // Determine color based on used allowance percentage
  const getAllowanceColor = (percentage) => {
    if (percentage <= 50) return "#4CAF50"; // Green
    if (percentage <= 80) return "#8BC34A"; // Light Green
    return "#FF4081"; // Pink Red
  };

  const allowanceColor = getAllowanceColor(parseFloat(usedAllowancePercentage));

  // SVG parameters
  const centerX = 100;
  const centerY = 100;
  const radius = 80;

  // Calculate end angle for consumed calories arc (starting from 12 o'clock position)
  const endAngle = (consumedPercentage / 100) * 359.99; // Use 359.99 instead of 360 to avoid full circle
  const endX = centerX + radius * Math.sin((endAngle * Math.PI) / 180);
  const endY = centerY - radius * Math.cos((endAngle * Math.PI) / 180);

  // Determine if the arc should take the long path (large-arc-flag)
  const largeArcFlag = endAngle > 180 ? 1 : 0;

  return (
    <>
    <div className="p-4">
      <button onClick={() => navigate(-1)} className="mb-4">
        <FontAwesomeIcon icon={faChevronLeft} /> Back
      </button>

      <div className="flex justify-center">
        <svg width="200" height="200" viewBox="0 0 200 200">
          {/* Define the filter for the shadow effect */}
          <defs>
            <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
              <feGaussianBlur in="SourceAlpha" stdDeviation="2" result="blur" />
              <feOffset in="blur" dx="2" dy="2" result="offsetBlur" />
              <feMerge>
                <feMergeNode in="offsetBlur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>

          {/* Background circle */}
          <circle cx={centerX} cy={centerY} r={radius} fill="#f0f0f0" />

          {/* Consumed calories arc */}
          <path
            d={`M ${centerX} ${centerY - radius} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`}
            fill="none"
            stroke={allowanceColor}
            strokeWidth="18"
          />

          {/* Inner circle for calories burned */}
          <circle cx={centerX} cy={centerY} r="60" fill="#FFA500" />

          {/* Text for calories burned with shadow */}
          <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            fill="white"
            fontSize="20"
            dy=".3em"
            filter="url(#shadow)"
          >
            Burned
            <tspan x={centerX} dy="1.2em">{Math.abs(caloriesBurned)}</tspan>
          </text>

          {/* Calorie goal text */}
          <text
            x={centerX}
            y={centerY + 100}
            textAnchor="middle"
            fill="#333"
            fontSize="14"
          >
          </text>
        </svg>
      </div>

      <div className="mt-4 grid grid-cols-2 gap-4">
        <div className="bg-blue-400 p-4 rounded-lg text-white text-center">
          <p className="font-medium">Calorie Goal</p>
          <p className="text-xl font-bold" style={embossedText}>{calorieGoal}</p>
        </div>
        <div className="bg-blue-400 p-4 rounded-lg text-white text-center">
          <p className="font-medium">Consumed</p>
          <p className="text-xl font-bold" style={embossedText}>{caloriesConsumed}</p>
        </div>
        <div className="bg-blue-400 p-4 rounded-lg text-white text-center">
          <p className="font-medium">Burned</p>
          <p className="text-xl font-bold" style={embossedText}>{Math.abs(caloriesBurned)}</p>
        </div>
        <div className="bg-blue-400 p-4 rounded-lg text-white text-center">
          <p className="font-medium">Used Allowance</p>
          <p className="text-xl font-bold" style={{ ...embossedText, color: allowanceColor }}>{usedAllowancePercentage}%</p>
        </div>
      </div>

      <div className="mt-4 text-center">

      </div>

      <div className="mt-4 text-center text-sm text-gray-500">
        <p>Your basal metabolic rate (Goal) is calculated using the following formula.</p>
        {userGenderState === 'male' ? (
          <p>(Male): 10 × weight (kg) + 6.25 × height (cm) - 5 × age + 5</p>
        ) : userGenderState === 'female' ? (
          <p>(Female): 10 × weight (kg) + 6.25 × height (cm) - 5 × age - 161</p>
        ) : (
          <>
            <p>(Male): 10 × weight (kg) + 6.25 × height (cm) - 5 × age + 5</p>
            <p>(Female): 10 × weight (kg) + 6.25 × height (cm) - 5 × age - 161</p>
          </>
        )}
      </div>
    </div>
    </>
  );
};

const embossedText = {
  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5), -1px -1px 2px rgba(255, 255, 255, 0.5)'
};

export default CalorieHistory;
