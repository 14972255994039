import React, { useState, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentQuestionState } from "../../../atom/survey/survey-atoms";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import Typewriter from "typewriter-effect";
import { userState } from "../../../atom/atoms";
import { addSurvey } from "../../../store/pouch/survey-pouch";
import { addSurveyQuestion, iterateAllSurveyQuestionsByUserEmail } from "../../../store/pouch/survey-questions-pouch";
import { getUserObj, updateUserEthnicity } from "../../../store/pouch/user-pouch";
import useTokenCheckVx from "../../../hooks/useTokenCheckVx";

import vxInstance from "../../../api/VxApiService";

const QNum400 = () => {
  const [user, setUser] = useRecoilState(userState);
  const [currentQuestion, setCurrentQuestion] =
    useRecoilState(currentQuestionState);
  const nicknameAnswer = useRecoilValue(questionAnswerState("100"));
  const [ethnicityAnswer, setEthnicityAnswer] = useRecoilState(
    questionAnswerState("400"),
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isApiConnected, isLoading } = useTokenCheckVx();

  const canSyncWithServer = useMemo(() => isApiConnected && !isLoading, [isApiConnected, isLoading]);

  const nickname = nicknameAnswer?.surveyAnswerString || "";
  const selectedEthnicity = ethnicityAnswer?.surveyAnswerString || "";

  const handleEthnicitySelect = async (ethnicity) => {
    const answerJson = {
      _id: `${user?.userEmail}-surveyQNum-400`,
      surveyQuestionNumber: "400",
      surveyAnswerString: ethnicity,
      surveyAnswerList: [],
      userEmail: user?.userEmail,
    };

    try {
      // Add survey question
      await addSurveyQuestion(answerJson);

      // Update user ethnicity
      await updateUserEthnicity(user?.userEmail, ethnicity);

      setEthnicityAnswer(answerJson);
      setIsModalOpen(false);

      // Update local user state
      setUser(prevUser => ({
        ...prevUser,
        userEthnicity: ethnicity
      }));

      // Automatically call handleNext after selecting ethnicity
      await handleNext();
    } catch (error) {
      console.error("Error saving survey question or updating user ethnicity:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleBack = () => {
    setCurrentQuestion("300");
  };

  const handleNext = async () => {
    try {
      let userSurveyProgress = {
        currentQuestion: "500",
        isComplete: false,
        completionDateTime: null,
        earnedCoinAmount: 0,
        earnedCoinDatetime: null,
      };

      await addSurvey({ _id: user?.userEmail, ...userSurveyProgress });
      
      if (canSyncWithServer) {
        try {
          let allQuestions = await iterateAllSurveyQuestionsByUserEmail(user?.userEmail);
          const userQuestions = allQuestions.filter(q => q.userEmail === user?.userEmail);
          vxInstance.surveyClientToServerUpsert(user?.accessToken, user?.refreshToken, {surveyData: userQuestions});
          let userObj = await getUserObj(user?.userEmail);
          
          vxInstance.userClientToServerUpsert(user?.accessToken, user?.refreshToken, userObj);
          console.log("Survey data and User data synchronized with server");
        } catch (apiError) {
          console.error("Failed to synchronize with server:", apiError);
          // We don't throw here to allow offline progression
        }
      } else {
        console.log("Offline mode or loading: Survey data saved locally");
      }

      setCurrentQuestion("500");
    } catch (error) {
      console.error("Error handling next:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const ethnicities = ["Malay", "Chinese", "Indian", "Others"];

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return (
    <>
      <div className="flex justify-center mt-1">
        <img
          src={`${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`}
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>
      <div className="flex justify-center mt-4">
        <div className="text-center text-xl font-thin text-blue-600">
          {selectedEthnicity && <span>{nickname}, you're considered as?</span>}
          {!selectedEthnicity && (
            <Typewriter
              options={{
                strings: [`${nickname}, you're considered as?`],
                autoStart: true,
                loop: false,
                deleteSpeed: Infinity,
                cursor: "",
              }}
            />
          )}
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <button
          type="button"
          onClick={() => setIsModalOpen(true)}
          className={`mt-2 px-4 py-2 rounded border-2 ${
            selectedEthnicity
              ? "border border-blue-500 bg-white text-blue-500"
              : "bg-blue-500 text-white"
          }`}
        >
          {selectedEthnicity || "Select Ethnicity"}
        </button>
      </div>
      <div className="flex justify-center mt-4">
        <div className="text-center text-xl font-thin text-blue-600">
          This is needed for research relevance to the Malaysian population.
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <button
          type="button"
          onClick={handleBack}
          className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-2"
        >
          Back
        </button>
        {selectedEthnicity && (
          <button
            type="button"
            onClick={handleNext}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
          >
            Next
          </button>
        )}
      </div>
      <div  className="flex justify-center mt-2">
        <img
          src={`${process.env.PUBLIC_URL}/daily2/03.0-MalaysiaFlag.svg`}
          alt="Malaysia Flag"
          className="w-32 h-16 object-cover"
        />
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Select Ethnicity</h2>
            <div className="flex flex-col space-y-2 max-h-64 overflow-y-auto">
              {ethnicities.map((ethnicity) => (
                <button
                  key={ethnicity}
                  onClick={() => handleEthnicitySelect(ethnicity)}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {ethnicity}
                </button>
              ))}
            </div>
            <button
              onClick={() => setIsModalOpen(false)}
              className="mt-4 bg-gray-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default QNum400;
