import { useRecoilState, useSetRecoilState } from 'recoil';
import { questionAnswerState } from '../atom/survey/survey-questions-atoms';
import { getSurveyQuestion } from '../store/pouch/survey-questions-pouch';
import { addUserObj, getUserObj } from '../store/pouch/user-pouch';
import { addLevelUp, getLastLevelUp } from '../store/pouch/level-up-pouch';
import { userState } from '../atom/atoms';
import { addSurvey, getSurvey } from '../store/pouch/survey-pouch';
import { currentQuestionState, surveyCompletionState } from '../atom/survey/survey-atoms';
import vxOAuthInstance from '../api/VxOAuthService';
import vxInstance from '../api/VxApiService';

const useUserInfoFromSurvey = (email, setEmail, apiVersion = "v0") => {
    const [user, setUser] = useRecoilState(userState);
    const setSurveyCompletion = useSetRecoilState(surveyCompletionState);
    const [currentQuestion, setCurrentQuestion] = useRecoilState(currentQuestionState);
    const [questionAnswer100, setQuestionAnswer100] = useRecoilState(questionAnswerState("100"));
    const [questionAnswer200, setQuestionAnswer200] = useRecoilState(questionAnswerState("200"));
    const [questionAnswer300, setQuestionAnswer300] = useRecoilState(questionAnswerState("300"));
    const [questionAnswer400, setQuestionAnswer400] = useRecoilState(questionAnswerState("400"));
    const [questionAnswer500, setQuestionAnswer500] = useRecoilState(questionAnswerState("500"));
    const [questionAnswer501, setQuestionAnswer501] = useRecoilState(questionAnswerState("501"));
    const [questionAnswer600, setQuestionAnswer600] = useRecoilState(questionAnswerState("600"));
    const [questionAnswer700, setQuestionAnswer700] = useRecoilState(questionAnswerState("700"));
    const [questionAnswer800, setQuestionAnswer800] = useRecoilState(questionAnswerState("800"));

    const fetchUserInfo = async () => {

        const questions = [
            { num: "100", key: "userNickName" },
            { num: "200", key: "userGender" },
            { num: "300", key: "userAgeGroup" },
            { num: "400", key: "userEthnicity" },
            { num: "500", key: "userPhone" },
            { num: "501", key: "userPhoneCountry" },
            { num: "600", key: "userInitialWeightKg" },
            { num: "700", key: "userHeightCm" },
            { num: "800", key: "userWaistInch" },
        ];

        const token = await vxOAuthInstance.getTokenByState();
        console.log("token", token);
        // Extract email from token
        const emailFromToken = token?.email;
        const name = token?.userName;

        let userObj = await getUserObj(emailFromToken);
        
        let newUserInfo = {
            _id: emailFromToken,
            email: emailFromToken,
            userEmail: emailFromToken,
            userName: name,
            userNickName: null,
            userPhoneCountry: null,
            userPhone: null,
            userRoles: [apiVersion],
            accessToken: token?.access_token,
            refreshToken: token?.refresh_token,
        };

        if(userObj) {
            newUserInfo = {...userObj, ...newUserInfo};
        }

        for (const { num, key } of questions) {
            const surveyQuestion = await getSurveyQuestion(`${email}-surveyQNum-${num}`);
            if (surveyQuestion && surveyQuestion.surveyAnswerString) {
                newUserInfo[key] = surveyQuestion.surveyAnswerString;
                switch (num) {
                case "100":
                    setQuestionAnswer100(surveyQuestion);
                    break;
                case "200":
                    setQuestionAnswer200(surveyQuestion);
                    break;
                case "300":
                    setQuestionAnswer300(surveyQuestion);
                    break;
                case "400":
                    setQuestionAnswer400(surveyQuestion);
                    break;
                case "500":
                    setQuestionAnswer500(surveyQuestion);
                    break;
                case "501":
                    setQuestionAnswer501(surveyQuestion);
                    break;
                case "600":
                    setQuestionAnswer600(surveyQuestion);
                    break;
                case "700":
                    setQuestionAnswer700(surveyQuestion);
                    break;
                case "800":
                    setQuestionAnswer800(surveyQuestion);
                    break;
                default:
                    break;
                }
            }
        }
        setEmail(emailFromToken); // Set the email state

        await addUserObj(newUserInfo);
        // Set the "active" userObj
        let activeUser = { ...newUserInfo, _id: "active" };
        await addUserObj(activeUser);

        //additional

        setUser(newUserInfo);

        //get level up
        let levelUpObj = await getLastLevelUp(emailFromToken);
        if(!levelUpObj) {
        await addLevelUp(activeUser.userEmail, 1, new Date().toISOString());
        }

        let userSurveyProgress = await getSurvey(emailFromToken);
        if (!userSurveyProgress) {
        userSurveyProgress = {
            currentQuestion: "100",
            isComplete: false,
            completionDateTime: null,
            earnedCoinAmount: 0,
            earnedCoinDatetime: null,
        };
        }

        if(userSurveyProgress.isComplete) {
        //set surveyCompletionState
        setSurveyCompletion(true);
        }

        await addSurvey({ 
            _id: activeUser.userEmail, 
            ...userSurveyProgress, 
            userCreatedDateTime: new Date().toISOString() 
        });

        setCurrentQuestion(userSurveyProgress?.currentQuestion);
        userObj = await getUserObj(emailFromToken);
        await vxInstance.userClientToServerUpsert(token?.access_token, 
            token?.refresh_token, userObj);
        //end additional

        return newUserInfo;
    };

    return { fetchUserInfo };
};

export default useUserInfoFromSurvey;