// src/component/Home.js
import React, { useEffect, useState, useRef } from "react";
import Typewriter from "typewriter-effect";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  completedStartScreenState,
  stateOfIntroductionState,
  userState,
} from "../atom/atoms";
import { surveyAtom, surveyCompletionState } from "../atom/survey/survey-atoms";
import { getNavigationItems } from "../store/pouch/navigation-pouch";
import "../input.css";
import "./Home.css";
import RandomGreeting from "./start/RandomGreeting";
import HomeMenu from "./start/menu/HomeMenu";
import { addUserObj, getUserObj } from "../store/pouch/user-pouch";
import { getQueryStringParams } from '../util/QueryStringUtil'; // Import the utility
import vxOAuthInstance from "../api/VxOAuthService";
import useUserInfoFromSurvey from '../hooks/useInfoFromSurveyVx'; // Import the custom hook

const Home = () => {
  const effectRan = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();
  const completedStartScreen = useRecoilValue(completedStartScreenState);
  const stateOfIntroduction = useRecoilValue(stateOfIntroductionState);
  const surveyCompletion = useRecoilValue(surveyCompletionState);
  //apiVersion is useState, read process.env.REACT_APP_ENABLE_API_V1 for "v1" else "v0"
  const [apiVersion, setApiVersion] = useState(process.env.REACT_APP_ENABLE_API_V1 ? "v1" : "v0");
  // State to hold the email
  const [email, setEmail] = useState(null);
  const { fetchUserInfo } = useUserInfoFromSurvey(email, setEmail, apiVersion); // Use the custom hook

  useEffect(() => {
    if (effectRan.current) return;
    effectRan.current = true;

    const processNavigationData = async () => {
      try {
        const url = new URL(window.location.href);
        // Get query parameters
        const queryParams = getQueryStringParams();
        console.log("qp", queryParams); // Access the parameters as needed

        // Example: Accessing access_token
        const state = queryParams?.state?.replaceAll("/", "");
        // Check if state equals to localStorage frontEndState
        if (state === localStorage.getItem("frontEndState")) {
          // Fetch user info from survey after setting the active user
          const userInfo = await fetchUserInfo();
          console.log("User Info from Survey:", userInfo);
        } else {
          console.log("state mismatch");
        }

        const usrObj = await getUserObj("active");
        let targetPath = null;

        if (!completedStartScreen) {
          targetPath = '/start';
        } else if (!usrObj) {
          targetPath = '/signup-login';
        } else if (!surveyCompletion) {
          targetPath = '/survey';
        }
                
        // Clear URL parameters
        const cleanUrl = `${window.location.origin}${window.location.pathname}${window.location.hash}`;
        window.history.replaceState({}, document.title, cleanUrl);

        // Navigate if necessary
        if (targetPath) {
          navigate(targetPath, { 
            replace: true,
            state: { clearSearch: true }
          });
        }

      } catch (error) {
        console.error("Error processing navigation items:", error);
      }
    };
    processNavigationData();
  }, [navigate, completedStartScreen, stateOfIntroduction, surveyCompletion, fetchUserInfo]);

  return (
    <>
      <div className="flex flex-col h-screen">
        <>
          <div className="text-xl font-thin text-center text-blue-600 mb-4">
            <div>
              {
                <Typewriter
                  options={{
                    strings: [`Welcome to healthChat`],
                    autoStart: true,
                    loop: false,
                    deleteSpeed: Infinity,
                    pauseFor: 99999,
                    cursor: "",
                  }}
                />
              }
            </div>
          </div>

          <div className="chatbot-image-container2">
            <img
              src={`${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`}
              alt="Chatbot"
              className="chatbot-image"
            />
          </div>
          <RandomGreeting />
        </>
        <HomeMenu />
      </div>
    </>
  );
};

export default Home;
