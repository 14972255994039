import { getSurveyQuestion, iterateAllSurveyQuestionsByUserEmail } from '../store/pouch/survey-questions-pouch';
import { getUserObj } from '../store/pouch/user-pouch';
import V0ApiService from './V0ApiService';
import axios from 'axios';

export class VxApiService extends V0ApiService {
  constructor(version = "v0") {
    super();
    this.version = version;
    this.baseURL = `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_PATH}/${this.version}`;
  }

  async oauthRequest(email) {
    try {
      const response = await axios.post(`${this.baseURL}/oauth`, {
        email: email
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error('OAuth request failed:', error);
      // throw error;
    }
  }

  async surveyClientToServerUpsert(accessToken, refreshToken, data) {
    try {
      const response = await axios.post(`${this.baseURL}/survey/client-to-server-upsert`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Refresh-Token': refreshToken
        }
      });
      return { data: response.data };
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          const newTokens = await this.refreshToken(refreshToken);
          const retryResponse = await axios.post(`${this.baseURL}/survey/client-to-server-upsert`, data, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${newTokens.accessToken}`,
              'X-Refresh-Token': newTokens.refreshToken
            }
          });
          return { 
            data: retryResponse.data, 
            newTokens: {
              accessToken: newTokens.accessToken,
              refreshToken: newTokens.refreshToken
            }
          };
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
          // throw new Error('Token refresh failed');
        }
      }
      console.error('Survey client to server upsert failed:', error);
      // throw error;
    }
  }

  async userClientToServerUpsert(accessToken, refreshToken, data) {
    try {
      const response = await axios.post(`${this.baseURL}/user/client-to-server-upsert`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Refresh-Token': refreshToken
        }
      });
      return { data: response.data };
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          const newTokens = await this.refreshToken(refreshToken);
          const retryResponse = await axios.post(`${this.baseURL}/user/client-to-server-upsert`, data, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${newTokens.accessToken}`,
              'X-Refresh-Token': newTokens.refreshToken
            }
          });
          
          return { 
            data: retryResponse.data, 
            newTokens: {
              accessToken: newTokens.accessToken,
              refreshToken: newTokens.refreshToken
            }
          };
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
          // throw new Error('Token refresh failed');
        }
      }
      console.error('User client to server upsert failed:', error);
      // throw error;
    }
  }

  async weightClientToServerUpsert(accessToken, refreshToken, data) {
    try {
      const response = await axios.post(`${this.baseURL}/weight/client-to-server-upsert`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Refresh-Token': refreshToken
        }
      });
      return { data: response.data };
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          const newTokens = await this.refreshToken(refreshToken);
          const retryResponse = await axios.post(`${this.baseURL}/weight/client-to-server-upsert`, data, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${newTokens.accessToken}`,
              'X-Refresh-Token': newTokens.refreshToken
            }
          });
          return { 
            data: retryResponse.data, 
            newTokens: {
              accessToken: newTokens.accessToken,
              refreshToken: newTokens.refreshToken
            }
          };
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
          // throw new Error('Token refresh failed');
        }
      }
      console.error('Weight client to server upsert failed:', error);
      // throw error;
    }
  }

  async lifeStyleClientToServerUpsert(accessToken, refreshToken, data) {
    try {
      const response = await axios.post(`${this.baseURL}/lifestyle/client-to-server-upsert`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Refresh-Token': refreshToken
        }
      });
      return { data: response.data };
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          const newTokens = await this.refreshToken(refreshToken);
          const retryResponse = await axios.post(`${this.baseURL}/lifestyle/client-to-server-upsert`, data, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${newTokens.accessToken}`,
              'X-Refresh-Token': newTokens.refreshToken
            }
          });
          return { 
            data: retryResponse.data, 
            newTokens: {
              accessToken: newTokens.accessToken,
              refreshToken: newTokens.refreshToken
            }
          };
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
          // throw new Error('Token refresh failed');
        }
      }
      console.error('Lifestyle client to server upsert failed:', error);
      // throw error;
    }
  }

  async checkNetwork(signal) {
    try {
      const response = await axios.get(`${this.baseURL}/network`, { signal });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Network check failed:', error);
      }
      // throw error;
    }
  }

  async refreshToken(refreshToken) {
    try {
      const response = await axios.post(`${this.baseURL}/oauth/refresh`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'X-Refresh-Token': refreshToken
        }
      });
      return response.data;
    } catch (error) {
      console.error('Token refresh failed:', error);
      // throw error;
    }
  }

  async checkTokenRole(accessToken, refreshToken, signal) {
    try {
      const response = await axios.get(`${this.baseURL}/network/check-token-role`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-Refresh-Token': refreshToken
        },
        signal
      });
      return { data: response.data };
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
        // throw error;
      }

      if (error.response && error.response.status === 401) {
        try {
          const newTokens = await this.refreshToken(refreshToken);
          const retryResponse = await axios.get(`${this.baseURL}/network/check-token-role`, {
            headers: {
              'Authorization': `Bearer ${newTokens.accessToken}`,
              'X-Refresh-Token': newTokens.refreshToken
            },
            signal
          });
          return { 
            data: retryResponse.data, 
            newTokens: {
              accessToken: newTokens.accessToken,
              refreshToken: newTokens.refreshToken
            }
          };
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
          // throw new Error('Token refresh failed');
        }
      }

      console.error('Token role check failed:', error);
      // throw error;
    }
  }

  async onlineCheckIn(email, accessToken, refreshToken, signal) {
    let surveyData = await iterateAllSurveyQuestionsByUserEmail(email);
    let userData = await getUserObj(email);
    let data = {
      surveyData: surveyData,
      userData: userData
    }
    try {
      const response = await axios.post(`${this.baseURL}/network/online-check-in`, data, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-Refresh-Token': refreshToken
        },
        signal
      });
      response.data = {...response.data, status: response.status};
      return response.data;
    } catch (error) {
      console.error('Online check-in failed:', error);
      // throw error;
    }
  }

  async analyzeCalories(accessToken, refreshToken, mealPhoto) {
    try {
      const response = await axios.post(`${this.baseURL}/meal/analyze-calories`, {
        mealPhoto: mealPhoto
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Refresh-Token': refreshToken
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          const newTokens = await this.refreshToken(refreshToken);
          const retryResponse = await axios.post(`${this.baseURL}/meal/analyze-calories`, {
            mealPhoto: mealPhoto
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${newTokens.accessToken}`,
              'X-Refresh-Token': newTokens.refreshToken
            }
          });
          return {
            data: retryResponse.data,
            newTokens: {
              accessToken: newTokens.accessToken,
              refreshToken: newTokens.refreshToken
            }
          };
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
        }
      }
      console.error('Analyze calories failed:', error);
    }
  }

  async exerciseClientToServerUpsert(accessToken, refreshToken, data) {
    try {
      const response = await axios.post(`${this.baseURL}/exercise/client-to-server-upsert`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Refresh-Token': refreshToken
        }
      });
      return response.data;
    } catch (error) {
      console.error('Exercise client to server upsert failed:', error);
      // throw error;
    }
  }

  async calorieClientToServerUpsert(accessToken, refreshToken, data) {
    try {
      const response = await axios.post(`${this.baseURL}/calorie/client-to-server-upsert`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Refresh-Token': refreshToken
        }
      });
      return response.data;
    } catch (error) {
      console.error('Calorie client to server upsert failed:', error);
      // throw error;
    }
  }

  async chatClientToServerUpsert(accessToken, refreshToken, data) {
    try {
      const response = await axios.post(`${this.baseURL}/chat/client-to-server-upsert`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Refresh-Token': refreshToken
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          const newTokens = await this.refreshToken(refreshToken);
          const retryResponse = await axios.post(`${this.baseURL}/chat/client-to-server-upsert`, data, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${newTokens.accessToken}`,
              'X-Refresh-Token': newTokens.refreshToken
            }
          });
          return {
            data: retryResponse.data,
            newTokens: {
              accessToken: newTokens.accessToken,
              refreshToken: newTokens.refreshToken
            }
          };
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
        }
      }
      console.error('Chat client to server upsert failed:', error);
    }
  }

  async DynamicClientToServerUpsert(dynamic, accessToken, refreshToken, data) {
    try {
      const response = await axios.post(`${this.baseURL}/${dynamic}/client-to-server-upsert`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
          'X-Refresh-Token': refreshToken
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          const newTokens = await this.refreshToken(refreshToken);
          const retryResponse = await axios.post(`${this.baseURL}/${dynamic}/client-to-server-upsert`, data, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${newTokens.accessToken}`,
              'X-Refresh-Token': newTokens.refreshToken
            }
          });
          return {
            data: retryResponse.data,
            newTokens: {
              accessToken: newTokens.accessToken,
              refreshToken: newTokens.refreshToken
            }
          };
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
        }
      }
      console.error(dynamic + ' client to server upsert failed:', error);
    }
  }

  async uploadSingleFileForMeal(accessToken, refreshToken, file, request) {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('request', JSON.stringify(request));

      const response = await axios.post(`${this.baseURL}/upload/upload-single-file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`,
          'X-Refresh-Token': refreshToken
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          const newTokens = await this.refreshToken(refreshToken);
          const formData = new FormData();
          formData.append('file', file);
          formData.append('request', JSON.stringify(request));

          const retryResponse = await axios.post(`${this.baseURL}/upload/upload-single-file`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${newTokens.accessToken}`,
              'X-Refresh-Token': newTokens.refreshToken
            }
          });
          return {
            data: retryResponse.data,
            newTokens: {
              accessToken: newTokens.accessToken,
              refreshToken: newTokens.refreshToken
            }
          };
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
        }
      }
      console.error('File upload failed:', error);
    }
  }
}

const vxInstance = process.env.REACT_APP_ENABLE_API_V1 === "true" ? new VxApiService("v1") : new VxApiService();
export default vxInstance;