import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
PouchDB.plugin(PouchDBFind);

const chatDB = new PouchDB("chatDB");

export const getChatsByUserEmail = async (userEmail) => {
  try {
    const allDocs = await chatDB.allDocs({
      include_docs: true,
    });

    const filteredDocs = allDocs.rows
      .map(row => row.doc)
      .filter(doc => doc.userEmail === userEmail && doc.chatDateTime);

    filteredDocs.sort((a, b) => new Date(b.chatDateTime) - new Date(a.chatDateTime));

    return filteredDocs;
  } catch (error) {
    console.error("Error retrieving chats for user email:", error);
    return [];
  }
};

export const getChatsByUserEmailAndTopicId = async (userEmail, chatTopicId) => {
  try {
    const allDocs = await chatDB.allDocs({
      include_docs: true,
    });

    const filteredDocs = allDocs.rows
      .map(row => row.doc)
      .filter(doc => 
        doc.userEmail === userEmail &&
        doc.chatTopicId === chatTopicId &&
        doc.initiatingParty &&
        doc.chatDateTime &&
        doc.chatText
      );

    filteredDocs.sort((a, b) => {
      if (a.chatDateTime !== b.chatDateTime) return new Date(b.chatDateTime) - new Date(a.chatDateTime);
      return a.chatText.localeCompare(b.chatText);
    });

    return filteredDocs;
  } catch (error) {
    console.error("Error retrieving chats for user email and topic ID:", error);
    return [];
  }
};

export const getChatsByUserEmailAndDateTimeString = async (
  userEmail,
  startDateTime = null,
  endDateTime = null,
  chatType = null,
) => {
  try {
    const allDocs = await chatDB.allDocs({
      include_docs: true,
    });

    const filteredDocs = allDocs.rows
      .map(row => row.doc)
      .filter(doc => 
        doc.userEmail === userEmail &&
        (startDateTime === null || new Date(doc.chatDateTime) >= new Date(startDateTime)) &&
        (endDateTime === null || new Date(doc.chatDateTime) <= new Date(endDateTime)) &&
        (!chatType || doc.chatType === chatType)
      );

    filteredDocs.sort((a, b) => {
      return new Date(b.chatDateTime) - new Date(a.chatDateTime);
    });

    return filteredDocs;
  } catch (error) {
    console.error("Error retrieving chats for user within date range:", error);
    return [];
  }
};

export const addChatItem = async (item) => {
  try {
    const existingItem = await chatDB.get(item._id).catch((err) => {
      if (err.status === 404) {
        return null;
      }
      throw err;
    });
    if (existingItem) {
      item._rev = existingItem._rev;
    }
    await chatDB.put(item);
  } catch (error) {
    if (error.name === "conflict") {
      try {
        const resolvedItem = await chatDB.get(item._id);
        item._rev = resolvedItem._rev;
        await chatDB.put(item);
      } catch (retryError) {
        console.log(retryError);
      }
    } else {
      console.log(error);
    }
  }
};

export const getChatById = async (id) => {
  try {
    const byId = await chatDB.get(id);
    console.log("Chat byId retrieved:", byId);
    return byId;
  } catch (error) {
    if (error.status === 404) {
      console.warn("Chat byId not found:", id);
      return null;
    }
    console.log("Error retrieving chat:", error);
    return null;
  }
};

export const getUniqueChatTopics = async (userEmail) => {
  try {
    const result = await chatDB.find({
      selector: {
        userEmail: userEmail,
        chatTopic: { $exists: true },
        chatTopicId: { $exists: true },
        chatTopicDateTime: { $exists: true },
      },
      fields: ["userEmail", "chatTopicDateTime", "chatTopicId", "chatTopic"],
      sort: [
        { userEmail: "asc" },
        { chatTopic: "asc" },
        { chatTopicId: "asc" },
        { chatTopicDateTime: "desc" },
      ],
    });

    // Create a map to ensure uniqueness based on chatTopicId
    const uniqueTopicsMap = result.docs.reduce((map, doc) => {
      if (!map.has(doc.chatTopicId)) {
        map.set(doc.chatTopicId, {
          chatTopicId: doc.chatTopicId,
          chatTopic: doc.chatTopic,
        });
      }
      return map;
    }, new Map());

    // Convert the map values to an array
    const uniqueTopics = Array.from(uniqueTopicsMap.values());

    return uniqueTopics;
  } catch (err) {
    console.error("Error fetching unique chat topics:", err);
    throw err;
  }
};

export const deleteChatItem = async (id) => {
  try {
    const item = await chatDB.get(id);
    const response = await chatDB.remove(item);
    return response;
  } catch (error) {
    console.error("Error deleting chat item:", error);
    throw error;
  }
};

export default chatDB;
