// src/component/avatar/signup-login/signup-login.js
import React, { useState, useEffect } from "react";
import { faGoogle, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SignupLogin.css";
import Ripples from "react-ripples";
import { preloginState, userState } from "../../../atom/atoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import { currentQuestionState, surveyCompletionState } from "../../../atom/survey/survey-atoms";
import { useNavigate } from "react-router-dom";
import { addUserObj, getUserObj } from "../../../store/pouch/user-pouch";
import { addSurvey, getSurvey } from "../../../store/pouch/survey-pouch";
import { addLevelUp, getLastLevelUp } from "../../../store/pouch/level-up-pouch";
import vxInstance from "../../../api/VxApiService";
import { jwtDecode } from "jwt-decode";
import { getSurveyQuestion } from "../../../store/pouch/survey-questions-pouch";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import MyEnv from "../../../util/MyEnv";
import { getGoogleOAuthUrl } from '../../../util/OAuthGoogleUtil';
import { getQueryStringParams } from '../../../util/QueryStringUtil';
import OtpComponent from "./OtpComponent";
import vxOAuthInstance from "../../../api/VxOAuthService";
import { addOtp, matchOtpByUserEmail, setOtpIsValidFalseByUserEmail } from "../../../store/pouch/otp-pouch";
import { isValid } from "date-fns";
import { IntervalCountdown } from "./interval-countdown";

const SignupLogin = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userState);
  const [prelogin, setPrelogin] = useRecoilState(preloginState);
  const [currentQuestion, setCurrentQuestion] = useRecoilState(currentQuestionState);
  const [loginEmail, setLoginEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [apiVersion, setApiVersion] = useState(process.env.REACT_APP_ENABLE_API_V1 === "true" ? "v1" : "v0");
  const setSurveyCompletion = useSetRecoilState(surveyCompletionState);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [questionAnswer100, setQuestionAnswer100] = useRecoilState(
    questionAnswerState("100"),
  );
  const [questionAnswer200, setQuestionAnswer200] = useRecoilState(
    questionAnswerState("200"),
  );
  const [questionAnswer300, setQuestionAnswer300] = useRecoilState(
    questionAnswerState("300"),
  );
  const [questionAnswer400, setQuestionAnswer400] = useRecoilState(
    questionAnswerState("400"),
  );

  const [questionAnswer500, setQuestionAnswer500] = useRecoilState(
    questionAnswerState("500"),
  );

  const [questionAnswer501, setQuestionAnswer501] = useRecoilState(
    questionAnswerState("501"),
  );

  const [questionAnswer502, setQuestionAnswer502] = useRecoilState(
    questionAnswerState("502"),
  );

  const [questionAnswer600, setQuestionAnswer600] = useRecoilState(
    questionAnswerState("600"),
  );

  const [questionAnswer700, setQuestionAnswer700] = useRecoilState(
    questionAnswerState("700"),
  );

  const [questionAnswer800, setQuestionAnswer800] = useRecoilState(
    questionAnswerState("800"),
  );

  const [questionAnswer900, setQuestionAnswer900] = useRecoilState(
    questionAnswerState("900"),
  );

  const [questionAnswer1000, setQuestionAnswer1000] = useRecoilState(
    questionAnswerState("1000"),
  );

  const [otp, setOtp] = useState(['', '', '', '']);
  const [otpRequested, setOtpRequested] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState('');

  const handleTestLogin = () => {
    const awaitTestLogin = async () => {
      await setUserInfoAndRouteToSurvey("test01@dev.com", "Test User 01");
    };
    awaitTestLogin();
  };

  const forceUpdate = () => {
    setTriggerUpdate(prev => !prev);
  };

  const setUserInfoAndRouteToSurvey = async (email, name, userRoles = [], accessToken = null, refreshToken = null) => {
    try {

      let userObj = await getUserObj(email);

      let newUserInfo = {
        _id: email,
        email: email,
        userEmail: email,
        userName: name,
        userNickName: null,
        userPhoneCountry: null,
        userPhone: null,
        userRoles: userRoles,
        accessToken: accessToken,
        refreshToken: refreshToken,
      };

      if(userObj) {
        newUserInfo = {...userObj, ...newUserInfo};
      }

      let surveyQnum100 = await getSurveyQuestion(`${email}-surveyQNum-100`);
      if(surveyQnum100 && surveyQnum100.surveyAnswerString) {
        newUserInfo.userNickName = surveyQnum100.surveyAnswerString;
        setQuestionAnswer100(surveyQnum100);
      }

      let surveyQNum200 = await getSurveyQuestion(`${email}-surveyQNum-200`);
      if(surveyQNum200 && surveyQNum200.surveyAnswerString) {
        newUserInfo.userGender = surveyQNum200.surveyAnswerString;
        setQuestionAnswer200(surveyQNum200);
      }

      let surveyQNum300 = await getSurveyQuestion(`${email}-surveyQNum-300`);
      if(surveyQNum300 && surveyQNum300.surveyAnswerString) {
        newUserInfo.userAgeGroup = surveyQNum300.surveyAnswerString;
        setQuestionAnswer300(surveyQNum300);
      }

      let surveyQNum400 = await getSurveyQuestion(`${email}-surveyQNum-400`);
      if(surveyQNum400 && surveyQNum400.surveyAnswerString) {
        newUserInfo.userEthnicity = surveyQNum400.surveyAnswerString;
        setQuestionAnswer400(surveyQNum400);
      }

      let surveyQNum500 = await getSurveyQuestion(`${email}-surveyQNum-500`);
      if(surveyQNum500 && surveyQNum500.surveyAnswerString) {
        newUserInfo.userPhone = surveyQNum500.surveyAnswerString;
        setQuestionAnswer500(surveyQNum500);
      }

      let surveyQNum501 = await getSurveyQuestion(`${email}-surveyQNum-501`);
      if(surveyQNum501 && surveyQNum501.surveyAnswerString) {
        newUserInfo.userPhoneCountry = surveyQNum501.surveyAnswerString;
        setQuestionAnswer501(surveyQNum501);
      }

      let surveyQNum600 = await getSurveyQuestion(`${email}-surveyQNum-600`);
      if(surveyQNum600 && surveyQNum600.surveyAnswerString) {
        newUserInfo.userInitialWeightKg = surveyQNum600.surveyAnswerString;
        setQuestionAnswer600(surveyQNum600);
      }

      let surveyQNum700 = await getSurveyQuestion(`${email}-surveyQNum-700`);
      if(surveyQNum700 && surveyQNum700.surveyAnswerString) {
        newUserInfo.userHeightCm = surveyQNum700.surveyAnswerString;
        setQuestionAnswer700(surveyQNum700);
      }

      let surveyQNum800 = await getSurveyQuestion(`${email}-surveyQNum-800`);
      if(surveyQNum800 && surveyQNum800.surveyAnswerString) {
        newUserInfo.userWaistInch = surveyQNum800.surveyAnswerString;
        setQuestionAnswer800(surveyQNum800);
      }

      let surveyQNum900 = await getSurveyQuestion(`${email}-surveyQNum-900`);
      if(surveyQNum900 && surveyQNum900.surveyAnswerString) {
        setQuestionAnswer900(surveyQNum900);
      }

      let surveyQNum1000 = await getSurveyQuestion(`${email}-surveyQNum-1000`);
      if(surveyQNum1000 && surveyQNum1000.surveyAnswerString) {
        setQuestionAnswer1000(surveyQNum1000);
      }

      let activeUser = { ...newUserInfo, _id: "active" };

      await addUserObj(activeUser);
      await addUserObj(newUserInfo);

      setUser(newUserInfo);

      //get level up
      let levelUpObj = await getLastLevelUp(email);
      if(!levelUpObj) {
        await addLevelUp(activeUser.userEmail, 1, new Date().toISOString());
      }

      let userSurveyProgress = await getSurvey(email);
      if (!userSurveyProgress) {
        userSurveyProgress = {
          currentQuestion: "100",
          isComplete: false,
          completionDateTime: null,
          earnedCoinAmount: 0,
          earnedCoinDatetime: null,
        };
      }

      if(userSurveyProgress.isComplete) {
        //set surveyCompletionState
        setSurveyCompletion(true);
      }

      await addSurvey({ 
        _id: activeUser.userEmail, 
        ...userSurveyProgress, 
        userCreatedDateTime: new Date().toISOString() 
      });

      setCurrentQuestion(userSurveyProgress?.currentQuestion);
      userObj = await getUserObj(email);
      await vxInstance.userClientToServerUpsert(accessToken, refreshToken, userObj);

      // vxInstance.clearUserOtp(accessToken)


      navigate("/");
    } catch (error) {
      console.error("Error in setUserInfoAndRouteToSurvey:", error);
    }
  };

  const handleTestLogin2 = () => {
    navigate("/testcustom");
  };
  const handlePumpData = () => {
    navigate("/research-admin-offline");
  };

  const handleEmailChange = (e) => {
    setLoginEmail(e?.target?.value?.toLowerCase());
    setEmailError("");
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      // handleApiLogin();
    }
  };

  const handleOTPLogin = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(loginEmail)) {
      setEmailError("Please provide valid email");
      return;
    }

    try {
      const response = await vxInstance.oauthRequest(loginEmail);
      console.log("API Login response:", response);

      if (response?.access_token) {
        const decodedToken = jwtDecode(response.access_token);
        console.log("Decoded token:", decodedToken);

        if (decodedToken.roles && decodedToken.roles.includes(apiVersion)) {
          await setUserInfoAndRouteToSurvey(loginEmail, loginEmail, decodedToken.roles, response?.access_token, response?.refresh_token);
        } else {
          console.log(`User does not have the '${apiVersion}' role`);
        }
      } else {
        console.log("No access token received");
        setEmailError("Login failed. Please try again.");
      }
    } catch (error) {
      console.error("API Login failed:", error);
      setEmailError("Login failed. Please try again.");
    }
  };

  const handleLoginWithGmail = () => {
    window.location.href = getGoogleOAuthUrl();
  };

  const handleRequestOtp = () => {
    // Implement your OTP request logic here
    console.log('Requesting OTP for:', loginEmail);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(loginEmail)) {
      setEmailError("Please provide valid email");
      return;
    }
    const awaitRequestOtp = async () => {
      try {
        setOtpRequested(true);
        setPrelogin({
          ...prelogin,
          countDownOTP: 30});
        // let arrayCheckValidOtpByEmail;
        const response = await vxOAuthInstance.requestEmailOtp(loginEmail);
        
      } catch (error) {
        // console.error('Error requesting OTP:', error);
        setPrelogin({
          ...prelogin,
          countDownOTP: 5});
      }
    };
    
      awaitRequestOtp();
    
  };

  const handleCancelOtp = () => {
    setOtpRequested(false);
    setLoginEmail(''); // Clear email input
    setOtp(['', '', '', '']); // Reset OTP input
  };

  const handleSubmitOtp = () => {
    setVerificationStatus("verifying");
    const asyncFn = async () => {
        try {
            // Call email-otp-login here
            const response = await vxOAuthInstance.verifyEmailOtp(loginEmail, otp.join(''));
            console.log("OTP verification response:", response);
            debugger;
            //if response is 200, await setuserInfoAndRouteToSurvey()
            if (response?.access_token) {
              const decodedToken = jwtDecode(response.access_token);
              console.log("Decoded token:", decodedToken);
      
              if (decodedToken.roles && decodedToken.roles.includes(apiVersion)) {
                await setUserInfoAndRouteToSurvey(loginEmail, loginEmail, decodedToken.roles, response?.access_token, response?.refresh_token);
              } else {
                console.log(`User does not have the '${apiVersion}' role`);
              }
              setVerificationStatus("success");
            } else {
              console.log("No access token received");
              setEmailError("Login failed. Please try again.");
              setVerificationStatus("failure");
            }


        } catch (error) {
            console.error("Error during OTP verification:", error);
            setVerificationStatus("failure");
        }
    };
    asyncFn();
  };

  // Effect to handle OTP submission
  useEffect(() => {
    if (!otp.some(digit => digit === '')) {
      handleSubmitOtp(); // Call handleSubmitOtp if OTP is filled
    }
  }, [otp]); // Dependency on otp

  return (
    <div className="mt-2 flex-col signup-login-container">
      
      {(process.env.REACT_APP_ENABLE_API_V1 === "true") && (
        <div className="api-test-panel border-2 border-dashed border-gray-300 rounded-lg p-4 mt-4">
          <h3 className="text-lg font-semibold mb-4">Use Gmail Login</h3>
          
          <div className="flex justify-center mt-1">
                    <Ripples color="rgba(255, 255, 255, 0.3)">
                      <button
                        onClick={handleLoginWithGmail}
                        className="px-6 py-3 mb-4 font-bold text-white rounded-lg shadow-md bg-gradient-to-r from-red-500 to-red-700 shadow-red-500/15"
                      >
                        <FontAwesomeIcon icon={faGoogle} className="mr-2" />{" "}
                        Login Gmail
                      </button>
                    </Ripples>
                  </div>
        </div>
      )}
      
      {(process.env.REACT_APP_ENABLE_API_V0 === "true" || process.env.REACT_APP_ENABLE_API_V1 === "true") && (
        <>
          <div className="api-test-panel border-2 border-dashed border-gray-300 rounded-lg p-4 mt-4">
            <h3 className="text-lg font-semibold mb-4">Use OTP</h3>
            <div className="mb-4">
              <>
                {otpRequested ? (
                  <>
                    <label className="block mb-2">{loginEmail}</label>
                    <OtpComponent otp={otp} setOtp={setOtp} verificationStatus={verificationStatus} 
                    setVerificationStatus={setVerificationStatus} forceUpdate={forceUpdate} />
                    <button 
                      className="rounded-lg bg-blue-500 text-white px-4 py-2 hover:bg-blue-600 transition-colors duration-300 mr-2"
                      onClick={handleSubmitOtp}
                    >
                      Login/Signup
                    </button>
                    <button 
                      className="rounded-lg bg-red-500 text-white px-4 py-2 hover:bg-red-600 transition-colors duration-300 mt-2"
                      onClick={handleCancelOtp}
                    >
                      Change Email
                    </button>
                  </>
                ) : (
                  <>
                    <label htmlFor="loginEmail" className="block mb-2">Login Email:</label>
                    <input
                      type="email"
                      id="loginEmail"
                      placeholder="Login with your Email"
                      value={loginEmail}
                      onChange={handleEmailChange}
                      onKeyPress={handleKeyPress}
                      className={`w-full p-2 border rounded ${emailError ? 'border-red-500' : 'border-gray-300'}`}
                    />
                    {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
                  </>
                )}
                <button className={`rounded-lg bg-blue-500 text-white px-4 py-2 
                    hover:bg-blue-600 transition-colors duration-300 mt-2 ${
                    prelogin?.countDownOTP ? 'cursor-not-allowed opacity-50' : ''
                  }`}
                  onClick={handleRequestOtp}
                  disabled={prelogin?.countDownOTP}
                >
                  Request OTP {!!prelogin?.countDownOTP && (
                  <IntervalCountdown />
                )}
                </button>
              </>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SignupLogin;