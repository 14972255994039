import React, { useState, useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentQuestionState } from "../../../atom/survey/survey-atoms";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import Typewriter from "typewriter-effect";
import WeightPicker from "./WeightPicker";
import { userState } from "../../../atom/atoms";
import { addSurvey } from "../../../store/pouch/survey-pouch";
import { addSurveyQuestion, iterateAllSurveyQuestionsByUserEmail } from "../../../store/pouch/survey-questions-pouch";
import { getUserObj, updateUserInitialWeight } from "../../../store/pouch/user-pouch";
import useTokenCheckVx from "../../../hooks/useTokenCheckVx";

import vxInstance from "../../../api/VxApiService";

const QNum600 = () => {
  const [user, setUser] = useRecoilState(userState);
  const [currentQuestion, setCurrentQuestion] =
    useRecoilState(currentQuestionState);
  const nicknameAnswer = useRecoilValue(questionAnswerState("100"));
  const [weightAnswer, setWeightAnswer] = useRecoilState(
    questionAnswerState("600"),
  );
  const [weight, setWeight] = useState("");
  const [error, setError] = useState("");

  const { isApiConnected, isLoading } = useTokenCheckVx();

  const canSyncWithServer = useMemo(() => isApiConnected && !isLoading, [isApiConnected, isLoading]);

  const nickname = nicknameAnswer?.surveyAnswerString || "";

  useEffect(() => {
    setWeight(weightAnswer?.surveyAnswerString || "");
  }, [weightAnswer]);

  const handleSaveWeight = async () => {
    if (!weight || parseFloat(weight) < 30 || parseFloat(weight) > 199.9) {
      setError("Please input valid weight (30-199.9 kg).");
      return;
    }

    setError("");

    const answerJson = {
      _id: `${user?.userEmail}-surveyQNum-600`,
      surveyQuestionNumber: "600",
      surveyAnswerString: weight,
      surveyAnswerList: [],
      userEmail: user?.userEmail,
    };

    try {
      // Add survey question
      await addSurveyQuestion(answerJson);

      // Update user's initial weight in PouchDB
      await updateUserInitialWeight(user?.userEmail, parseFloat(weight));

      setWeightAnswer(answerJson);

      let userSurveyProgress = {
        currentQuestion: "700",
        isComplete: false,
        completionDateTime: null,
        earnedCoinAmount: 0,
        earnedCoinDatetime: null,
      };

      await addSurvey({ _id: user?.userEmail, ...userSurveyProgress });
      
      // Update local user state
      setUser(prevUser => ({
        ...prevUser,
        userInitialWeightKg: parseFloat(weight)
      }));

      if (canSyncWithServer) {
        try {
          let allQuestions = await iterateAllSurveyQuestionsByUserEmail(user?.userEmail);
          const userQuestions = allQuestions.filter(q => q.userEmail === user?.userEmail);
          vxInstance.surveyClientToServerUpsert(user?.accessToken, user?.refreshToken, {surveyData: userQuestions});
          let userObj = await getUserObj(user?.userEmail);
          vxInstance.userClientToServerUpsert(user?.accessToken, user?.refreshToken, userObj);

          console.log("Survey data and User data synchronized with server");
        } catch (apiError) {
          console.error("Failed to synchronize with server:", apiError);
          // We don't throw here to allow offline progression
        }
      } else {
        console.log("Offline mode or loading: Survey data saved locally");
      }

      setCurrentQuestion("700");
    } catch (error) {
      console.error("Error saving survey question or updating user weight:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleBack = () => {
    setCurrentQuestion("500");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSaveWeight();
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return (
    <>
      <div className="flex justify-center mt-1">
        <img
          src={`${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`}
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>
      <div className="flex justify-center mt-4">
        <div className="text-center text-xl font-thin text-blue-600">
          {weight && (
            <>
              {nickname}, to understand
              <br /> your overall health,
              <br />
              mind sharing your weight🏋️‍♀️?
            </>
          )}
          {!weight && (
            <Typewriter
              options={{
                strings: [
                  `${nickname}, to understand<br/> your overall health,<br/>mind sharing your weight🏋️‍♀️?`,
                ],
                autoStart: true,
                loop: false,
                deleteSpeed: Infinity,
                cursor: "",
              }}
            />
          )}
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <WeightPicker
          weight={weight}
          setWeight={setWeight}
          handleKeyPress={handleKeyPress}
        />
      </div>
      {error && (
        <div className="flex justify-center mt-2">
          <p className="text-red-500">{error}</p>
        </div>
      )}
      <div className="flex justify-center mt-2">
        <button
          type="button"
          onClick={handleBack}
          className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-2"
        >
          Back
        </button>
        <button
          type="button"
          onClick={handleSaveWeight}
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
        >
          Next
        </button>
      </div>

      <div  className="flex justify-center mt-2">
        <img
          src={`${process.env.PUBLIC_URL}/daily2/02.0-weighing-scale.svg`}
          alt="Weighing Scale"
          className="w-32 h-32 object-cover"
        />
      </div>
    </>
  );
};

export default QNum600;