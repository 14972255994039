// src/component/avatar/signup-login/OtpComponent.js
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { useRef, useEffect } from 'react';

const OtpComponent = ({ otp, setOtp, verificationStatus, setVerificationStatus, forceUpdate }) => {
  const inputRefs = useRef([]);

  useEffect(() => {
    // Focus the first input on component mount
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    if(!newOtp.some(digit => digit === '')) {
      // handleSubmitOtp();
      forceUpdate();
    } else if (/^[0-9]$/.test(value)) {
      newOtp[index] = value.slice(-1); // Only keep the last character
    } else {
      newOtp[index] = ''; // Clear if not numeric
    }

    setOtp(newOtp);

    // Move to next input if current field is filled
    if (newOtp[index] && index < 3) {
      inputRefs.current[index + 1].focus();
      inputRefs.current[index + 1].select();
    }
  };

  const handleKeyDown = (index, e) => {
    // Allow pasting with Ctrl+V or Cmd+V
    if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
      return; // Allow paste
    }

    // Allow only numeric input and handle backspace
    if (e.key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1].focus();
      // inputRefs.current[index - 1].select(); // Highlight previous input
    } else if (e.key === 'ArrowRight' && index < 3) {
      inputRefs.current[index + 1].focus();
      // inputRefs.current[index + 1 ].select(); // Highlight next input
    } else {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
        // Move to previous input on backspace if current field is empty
        inputRefs.current[index - 1].focus();
        inputRefs.current[index - 1].select();
      } else if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace') {
        e.preventDefault(); // Prevent non-numeric input
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, 4).replace(/[^0-9]/g, '').split('');
    const newOtp = [...otp];
    
    pastedData.forEach((value, index) => {
      if (index < 4) {
        newOtp[index] = value;
      }
    });
    setOtp(newOtp);
    // Focus the next empty input or the last input
    const nextEmptyIndex = newOtp.findIndex(val => val === '');
    const focusIndex = nextEmptyIndex === -1 ? 3 : nextEmptyIndex;
    inputRefs.current[focusIndex].focus();

    if(!newOtp.some(digit => digit === '')) {
      // handleSubmitOtp();
      forceUpdate();
    }

  };

  const handleClearOtp = () => {
    setOtp(Array(4).fill(''));
    setVerificationStatus('idle');
  };

  return (
    <div className="flex space-x-2 mb-4">
      {verificationStatus === 'verifying' ? (
      <div className="flex items-center justify-center">
        <FontAwesomeIcon icon={faSpinner} className="mr-2 spin" />{" "}
        <span className="text-blue-500 ml-2">Verifying OTP...</span>
      </div>
    ) : verificationStatus === 'success' ? (
      <span className="text-blue-500">OTP verified successfully!</span>
    ) : (<>
        <div className="flex flex-col space-y-2"> {/* Main container with column layout */}
          <div className="flex flex-row">
            {otp.map((digit, index) => (
              <div className="mr-1">
              <input
                key={index}
                ref={el => inputRefs.current[index] = el}
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength={1}
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                onPaste={handlePaste}

                className="w-12 h-12 border rounded text-center text-lg"
              />
              </div>
            ))}
          </div>
          {verificationStatus === 'failure' && (
            <div className="flex items-center justify-center text-red-500" 
            onClick={handleClearOtp}>
              <FontAwesomeIcon icon={faTimes} className="mr-2" />
              <span>OTP invalid. Click to clear.</span>
            </div>
          )}
        </div>
      </>)}
    </div>
  );
};

export default OtpComponent;