import React, { useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "./NextLevel.css";
import NextLevelHorizontalViewer from "./NextLevelHorizontalViewer";
import CoinHistory from "./coin/CoinHistory";
import LevelProgress from "./LevelProgress";
import { userState } from "../../../atom/atoms";
import {
  getAllCoins,
  getCoinsByUserEmail,
  getCoinsByUserEmailAndDateTimeRange,
} from "../../../store/pouch/coin-pouch";
import { convertYYYYMMDDToDateObj } from "../../../util/Util";
import {
  addCoinHistories,
  coinHistories,
  coinTodayAtom,
} from "../../../atom/coin/coin-today-atom";
import {
  format,
  isToday,
  isYesterday,
  isThisWeek,
  isThisYear,
  formatDistanceToNow,
} from "date-fns";
import { toZonedTime as utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

const NextLevel = () => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const effectRan = useRef(false);
  const setCoinHistories = useSetRecoilState(coinHistories);
  const [showCoinHistory, setShowCoinHistory] = useState(true);

  const handleScroll = () => {
    if (containerRef.current) {
      const scrollPosition = containerRef.current.scrollTop;
    }
  };

  const handleViewerItemClick = (item) => {
    if (item === 'Level Progress') {
      setShowCoinHistory(false);
    } else {
      setShowCoinHistory(true);
    }
  };

  useEffect(() => {
    if (effectRan.current === false) {
      debugger;
      const asyncWrapper = async () => {
        const mapCoinData = (coinArray) => {
          const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          return coinArray.map((coin) => {
            const txDate = utcToZonedTime(
              new Date(coin.coinTxDateTime),
              userTimeZone,
            );
            const now = utcToZonedTime(new Date(), userTimeZone);

            // debugger;
            let coinTimeCluster;
            if (
              txDate.getDate() === now.getDate() &&
              txDate.getMonth() === now.getMonth() &&
              txDate.getFullYear() === now.getFullYear()
            ) {
              coinTimeCluster = "Today";
            } else if (isYesterday(txDate)) {
              coinTimeCluster = "Yesterday";
            } else if (isThisWeek(txDate)) {
              coinTimeCluster = "This Week";
            } else if (isThisYear(txDate)) {
              coinTimeCluster = format(txDate, "MMMM");
            } else {
              coinTimeCluster = format(txDate, "yyyy");
            }
            let coinTxDisplayTime;
            if (isToday(txDate)) {
              coinTxDisplayTime = formatDistanceToNow(txDate, {
                addSuffix: true,
              });
            } else if (isYesterday(txDate)) {
              coinTxDisplayTime = format(txDate, "'Yesterday at' h:mm a");
            } else if (isThisWeek(txDate)) {
              coinTxDisplayTime = format(txDate, "EEEE 'at' h:mm a"); // Day of week
            } else {
              coinTxDisplayTime = format(txDate, "MMM d, yyyy 'at' h:mm a");
            }

            return {
              coinId: coin._id,
              coinPurpose: coin.coinPurpose,
              coinsEarned: coin.coinsEarned,
              coinTxDateTime: coin.coinTxDateTime,
              coinTimeCluster,
              coinTxDisplayTime,
            };
          });
        };
        let coinArray = await getCoinsByUserEmail(user?.userEmail);

        // debugger;
        setCoinHistories(mapCoinData(coinArray));

        const container = containerRef.current;
        if (container) {
          container.addEventListener("scroll", handleScroll);
        }

        return () => {
          if (container) {
            container.removeEventListener("scroll", handleScroll);
          }
          effectRan.current = true;
        };
      };
      asyncWrapper();
    }
  }, [user?.userEmail, setCoinHistories]);
  return (
    <div className="flex flex-col h-screen">
      <div ref={containerRef} className="flex-1 overflow-y-auto">
        <button onClick={() => navigate(-1)} className="mr-4 text-blue-600 ">
          <FontAwesomeIcon icon={faChevronLeft} size="lg" /> Back
        </button>
        <div className="next-level-container">
          <div className="image-wrapper" onClick={() => navigate(-1)}>
            <img
              src={`${process.env.PUBLIC_URL}/daily2/11-next-level.jpg`}
              alt="next level"
              className="next-level-image"
            />
          </div>
          <div className="next-level-text">
            <div className="next-level-text-shadow text-center text-blue-400 text-2xl font-medium">
              You're at Level {user?.userLevel || 1}
            </div>
          </div>
          <NextLevelHorizontalViewer onItemClick={handleViewerItemClick} />
          {showCoinHistory ? <CoinHistory /> : <LevelProgress currentLevel={user?.userLevel || 1} nextLevel={(user?.userLevel || 1) + 1} task="Weighing for 2 consecutive days" progress={1} total={2} />}
        </div>
      </div>
    </div>
  );
};

export default NextLevel;
