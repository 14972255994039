import React, { useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "../../../atom/atoms";
import {
  surveyDataState,
  questionAnswerState,
} from "../../../atom/survey/survey-questions-atoms";
import Typewriter from "typewriter-effect";
import { currentQuestionState } from "../../../atom/survey/survey-atoms";
import { addSurvey } from "../../../store/pouch/survey-pouch";
import { addSurveyQuestion, iterateAllSurveyQuestionsByUserEmail } from "../../../store/pouch/survey-questions-pouch";
import { getUserObj, updateUserNickname } from "../../../store/pouch/user-pouch";
import VxApiService from '../../../api/VxApiService';
import { useNavigate } from "react-router-dom";
import useTokenCheckVx from "../../../hooks/useTokenCheckVx";
import vxInstance from "../../../api/VxApiService";
import { faHandHoldingHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const QNum100 = () => {
  
  const [user, setUser] = useRecoilState(userState);
  const [questionAnswer, setQuestionAnswer] = useRecoilState(
    questionAnswerState("100"),
  );
  const surveyData = useRecoilValue(surveyDataState);
  const setCurrentQuestion = useSetRecoilState(currentQuestionState);

  const [isTouched, setIsTouched] = React.useState(false);
  const { isApiConnected, isLoading } = useTokenCheckVx();
  const navigate = useNavigate();

  // Allow saving in both online and offline modes
  const canSaveUpsert = useMemo(() => !isLoading, [isLoading]);

  //useEffect debugger only
  

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z0-9 ]*$/.test(value)) {
      setUser({ ...user, userNickName: value });
    }
  };

  const handleNickNameKeyDown = (event) => {
    if (event.key === "Enter" && user?.userNickName !== "") {
      saveUpsert();
    }
  };

  const handleBlur = () => {
    setIsTouched(true);
  };

  const saveUpsert = async () => {
    if (!canSaveUpsert) {
      console.log("Cannot save: still loading");
      return;
    }

    try {
      // Update user nickname in the local database
      await updateUserNickname(user.userEmail, user.userNickName);
      
      const surveyQuestion = {
        _id: `${user?.userEmail}-surveyQNum-100`,
        surveyQuestionNumber: "100",
        surveyAnswerString: user.userNickName,
        surveyAnswerList: [],
        userEmail: user?.userEmail,
      };

      // Save survey question to PouchDB
      await addSurveyQuestion(surveyQuestion);
      

      setQuestionAnswer(surveyQuestion);

      let userSurveyProgress = {
        currentQuestion: "200",
        isComplete: false,
        completionDateTime: null,
        earnedCoinAmount: 0,
        earnedCoinDatetime: null,
      };

      await addSurvey({ _id: user?.userEmail, ...userSurveyProgress });

      setCurrentQuestion("200");
    } catch (error) {
      console.error("Error saving survey question:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleNext = async () => {
    if (user?.userNickName) {
      await saveUpsert();

      // Only attempt API synchronization if connected
      //if (isApiConnected) 
      // {
      
        try {
          let userObj = await getUserObj(user?.userEmail);
          await vxInstance.userClientToServerUpsert(user?.accessToken, user?.refreshToken, userObj);
          let allQuestions = await iterateAllSurveyQuestionsByUserEmail(user?.userEmail);
          const userQuestions = allQuestions.filter(q => q.userEmail === user?.userEmail);
          await VxApiService.surveyClientToServerUpsert(user?.accessToken, user?.refreshToken, {surveyData: userQuestions});
          console.log("Survey data synchronized with server");
        } catch (apiError) {
          console.error("Failed to synchronize with server:", apiError);
          // We don't throw here to allow offline progression
        }
      // } else {
      //   console.log("Offline mode: Survey data saved locally");
      // }
      
    } else {
      setIsTouched(true);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return (
    <>
      <div className="flex justify-center mt-4">
        <img
          src={`${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`}
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
        <div className="text-center text-xl text-blue-600 ml-2">
          <span>Let's setup Profile, </span>
          <br />
          <span>{user?.userName || "Guest"}</span>
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <div className="text-center text-xl font-thin text-blue-600">

        <span className="mr-2">
            <FontAwesomeIcon icon={faHandHoldingHeart} className="text-blue-400" /> 
          </span>
          {user?.userNickName && <span>What's your nickname?</span>}
          {!user?.userNickName && (
            <Typewriter
              options={{
                strings: [`What's your nickname?`],
                autoStart: true, loop: false, deleteSpeed: Infinity, cursor: "",
              }}
            />
          )}
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <div className="flex flex-col items-center mt-2">
          <input
            type="text"
            value={user?.userNickName || ""}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onKeyDown={handleNickNameKeyDown}
            placeholder="Enter your nickname"
            className="border p-2 rounded"
            maxLength={15}
          />
          {isTouched && !user?.userNickName && (
            <span className="text-red-500 mt-2">
              Nickname must not be empty
            </span>
          )}
          <button
            type="button"
            onClick={handleNext}
            className={`mt-2 px-4 py-2 text-white rounded ${
              !user?.userNickName || !canSaveUpsert
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500"
            }`}
            disabled={!user?.userNickName || !canSaveUpsert}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default QNum100;
