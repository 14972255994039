import React, { useState, useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentQuestionState } from "../../../atom/survey/survey-atoms";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import Typewriter from "typewriter-effect";
import WaistPicker from "./WaistPicker";
import { addSurvey } from "../../../store/pouch/survey-pouch";
import { userState } from "../../../atom/atoms";
import { addSurveyQuestion, iterateAllSurveyQuestionsByUserEmail } from "../../../store/pouch/survey-questions-pouch";
import { getUserObj, updateUserWaist } from "../../../store/pouch/user-pouch";
import useTokenCheckVx from "../../../hooks/useTokenCheckVx";

import vxInstance from "../../../api/VxApiService";

const QNum800 = () => {
  const [user, setUser] = useRecoilState(userState);
  const setCurrentQuestion = useRecoilState(currentQuestionState)[1];
  const nicknameAnswer = useRecoilValue(questionAnswerState("100"));
  const [waistAnswer, setWaistAnswer] = useRecoilState(
    questionAnswerState("800"),
  );
  const [waist, setWaist] = useState("");

  const { isApiConnected, isLoading } = useTokenCheckVx();

  const canSyncWithServer = useMemo(() => isApiConnected && !isLoading, [isApiConnected, isLoading]);

  const nickname = nicknameAnswer?.surveyAnswerString || "";

  useEffect(() => {
    setWaist(waistAnswer?.surveyAnswerString || "");
  }, [waistAnswer]);

  const handleSaveWaist = async () => {
    if (waist === "" || parseInt(waist) < 10) {
      return;
    }

    const answerJson = {
      _id: `${user?.userEmail}-surveyQNum-800`,
      surveyQuestionNumber: "800",
      surveyAnswerString: waist.toString(),
      surveyAnswerList: [],
      userEmail: user?.userEmail,
    };

    try {
      // Add survey question
      await addSurveyQuestion(answerJson);

      // Update user's waist in PouchDB
      await updateUserWaist(user?.userEmail, parseInt(waist, 10));

      setWaistAnswer(answerJson);

      let userSurveyProgress = {
        currentQuestion: "900",
        isComplete: false,
        completionDateTime: null,
        earnedCoinAmount: 0,
        earnedCoinDatetime: null,
      };

      await addSurvey({ _id: user?.userEmail, ...userSurveyProgress });
      
      // Update local user state
      setUser(prevUser => ({
        ...prevUser,
        userWaistInch: parseInt(waist, 10)
      }));

      if (canSyncWithServer) {
        try {
          let allQuestions = await iterateAllSurveyQuestionsByUserEmail(user?.userEmail);
          const userQuestions = allQuestions.filter(q => q.userEmail === user?.userEmail);
          vxInstance.surveyClientToServerUpsert(user?.accessToken, user?.refreshToken, {surveyData: userQuestions});
          let userObj = await getUserObj(user?.userEmail);
          vxInstance.userClientToServerUpsert(user?.accessToken, user?.refreshToken, userObj);

          console.log("Survey data and User data synchronized with server");
        } catch (apiError) {
          console.error("Failed to synchronize with server:", apiError);
          // We don't throw here to allow offline progression
        }
      } else {
        console.log("Offline mode or loading: Survey data saved locally");
      }

      setCurrentQuestion("900");
    } catch (error) {
      console.error("Error saving survey question or updating user waist:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleBack = () => {
    setCurrentQuestion("700");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSaveWaist();
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return (
    <>
      <div className="flex justify-center mt-1">
        <img
          src={`${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`}
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>
      <div className="flex justify-center mt-4">
        <div className="text-center text-xl font-thin text-blue-600">
          {waist ? (
            <>
              {nickname}, could you please tell me <br /> your waist
              circumference,
              <br /> measured at belly button?
            </>
          ) : (
            <>
              <>
                {nickname}, could you please tell me <br />your waist circumference,
              </>
              <Typewriter
                options={{
                  strings: [
                    `<br/> measured at belly button?`,
                  ],
                  autoStart: true,
                  loop: false,
                  deleteSpeed: Infinity,
                  cursor: "",
                }}
              />
            </>
          )}
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <WaistPicker
          waist={waist}
          setWaist={setWaist}
          handleKeyPress={handleKeyPress}
        />
      </div>
      <div className="flex justify-center mt-2">
        <button
          type="button"
          onClick={handleBack}
          className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-2"
        >
          Back
        </button>
        <button
          type="button"
          onClick={handleSaveWaist}
          className={`mt-2 px-4 py-2 ${
            waist === "" || parseInt(waist) < 10
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-500"
          } text-white rounded`}
          disabled={waist === "" || parseInt(waist) < 10}
        >
          Next
        </button>
      </div>

      <div  className="flex justify-center mt-2 ml-12">
        <img
          src={`${process.env.PUBLIC_URL}/daily2/08.2-waist.svg`}
          alt="waist"
          className="w-48 h-32 object-cover"
        />
      </div>
    </>
  );
};

export default QNum800;