// src/component/mission/DailyMission.js
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointRight, 
  faSquare,
  faCheckSquare,
  faChevronLeft,
  faWeightScale,
  faWalking,
  faBook
} from "@fortawesome/free-solid-svg-icons";
import "./DailyMission.css";
import Typewriter from "typewriter-effect";
import DailyMore from "./DailyMore";
import {
  getLocalDateOnly,
  getLocalDateTime,
  convertYYYYMMDDToDateObj,
  convertYYYYMMDDToDateObjEOD,
  getStartOfDayISOStringZ,
  getEndOfDayISOStringZ,
} from "../../util/Util";
import { CalculateCoinsUnclaimed, ClaimCoins } from "../../util/CoinClaiming";
import {
  getCoinsByUserEmailAndDateTimeRange,
  getCoinsByUserEmailAndDateTimeString,
} from "../../store/pouch/coin-pouch";
import { userState } from "../../atom/atoms";
import { getUserObj } from "../../store/pouch/user-pouch";
import { coinEarnedTodayState } from "../../atom/coin/coin-today-atom";
import { calorieAtom } from "../../atom/fatloss/calorie-atom";
import { getWeightByUserEmailAndDateTimeString } from "../../store/pouch/weight-pouch";
import { getLifestyleQuestionsByUserEmailAndDateTimeRange } from "../../store/pouch/lifestyle-pouch";
import { getHabitQuestionsByUserEmailAndDateTimeRange } from "../../store/pouch/habit-pouch";
import FloatingPointer from '../quiz/FloatingPointer';


const DailyMission = ({promptForNotificationPermission}) => {
  const [user, setUser] = useRecoilState(userState);
  const navigate = useNavigate();
  const [coinEarnedToday, setCoinEarnedToday] =
    useRecoilState(coinEarnedTodayState);

  const effectRan = useRef(false);
  const [habitAssessedToday, setHabitAssessedToday] = useState(false);
  const [lifestyleCompletedToday, setLifestyleCompletedToday] = useState(false);
  const [weighedToday, setWeighedToday] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleBack = () => {
    navigate(-1);
  };

  const handleLifestyleClicked = () => {
    if (lifestyleCompletedToday) {
      showModalWithMessage("You did Lifestyle score today.\r\n Please do again tomorrow.");
    } else {
      navigate("/lifestyle");
    }
  };

  const handleWeighingClicked = () => {
    navigate("/weighing");
  };

  const handleAssessHabits = () => {
    if (habitAssessedToday) {
      showModalWithMessage("You have assessed Habits today.\r\n Please do again tomorrow.");
    } else {
      navigate("/assess-habits");
    }
  };

  const handleMoreClicked = () => {
    navigate("/daily-more");
  };

  const showModalWithMessage = (message) => {
    setModalMessage(message);
    setShowModal(true);
    setTimeout(() => {
      closeModal();
    }, 5000);
  };

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setShowModal(false);
      setIsClosing(false);
    }, 360); // Match this duration with the CSS transition duration
  };

  useEffect(() => {
    if (effectRan.current === false) {
      const asyncWrapper = async () => {
        let coinsToday = await getCoinsByUserEmailAndDateTimeString(
          user?.userEmail,
          getStartOfDayISOStringZ(),
          getEndOfDayISOStringZ(),
        );
        setCoinEarnedToday(
          coinsToday.reduce(
            (sum, transaction) => sum + transaction.coinsEarned,
            0,
          ),
        );

        let weightHistoryToday = await getWeightByUserEmailAndDateTimeString(
          user?.userEmail,
          getStartOfDayISOStringZ(),
          getEndOfDayISOStringZ(),
        );
        setWeighedToday(weightHistoryToday.length > 0); // Set to true if there are weights today

        let lifestyleResponsesToday =
          await getLifestyleQuestionsByUserEmailAndDateTimeRange(
            user?.userEmail,
            getStartOfDayISOStringZ(),
            getEndOfDayISOStringZ(),
          );

        setLifestyleCompletedToday(lifestyleResponsesToday.length);

        let habitResponseToday =
          await getHabitQuestionsByUserEmailAndDateTimeRange(
            user?.userEmail,
            getStartOfDayISOStringZ(),
            getEndOfDayISOStringZ(),
          );

        setHabitAssessedToday(habitResponseToday.length);

        let activeUser = await getUserObj(user?.userEmail);
        //use prevState when setUser
        setUser((prevUser) => ({ ...prevUser, ...activeUser }));

        return () => {
          effectRan.current = true;
        };
      };
      asyncWrapper();
    }
  }, [user?.userEmail, setCoinEarnedToday]);
  return (
    <div className="flex flex-col justify-center items-center mt-4">
      <div className="shadow-lg shadow-gray-500">
        <img
          src={`${process.env.PUBLIC_URL}/daily2/09-mission-banner.png`}
          alt="Chatbot Mission"
          className=""
        />
      </div>
      {false && (
        <div className="w-full flex justify-start px-4 mb-4">
          <button
            onClick={handleBack}
            className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
          >
            <FontAwesomeIcon icon={faChevronLeft} size="lg" />
          </button>
        </div>
      )}
      <div className="text-center text-2xl font-bold text-blue-400 mt-3">
        My Daily Mission
      </div>
      {/* add text shadow */}
      <div className="mt-2 p-2 text-center text-xl font-thin text-blue-600 ">
        You earned {coinEarnedToday} coins today, {user?.userNickName}.
      </div>

      {/* add picture justify center src={`${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`} */}
      <div className="flex justify-center items-center">
        <img
          src={`${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`}
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>

      {/* add typewriter Do missions to earn coins autoStart: true, loop: false, deleteSpeed: Infinity, cursor: "", */}
      <div className="flex justify-center mt-4 mb-2">  
        
        <div className="text-center text-xl font-thin text-blue-600">
          <Typewriter
            options={{
              strings: [`Do missions to earn coins`],
              autoStart: true, loop: false, deleteSpeed: Infinity, cursor: "",
            }}
          />
        </div>
      </div>

      <div className="flex w-full items-center mb-3 mr-5">
        <div className="w-1/4"
            onClick={handleWeighingClicked}>
          

        {!weighedToday && (
          <FloatingPointer onClick={handleWeighingClicked} />
        )}
        {weighedToday && (<FontAwesomeIcon
            icon={faCheckSquare}
            className={`w-full ${weighedToday ? "text-green-500" : "text-gray-400"}`}
            size="lg"
          />)}
        </div>
        <div className="w-3/4">
          <button
            onClick={handleWeighingClicked}
            className={`w-full py-3 rounded-lg transition-all duration-300 
              font-bold text-white shadow-lg bg-blue-400 shadow-blue-800/35
               flex items-center justify-center 
            `}
          >
            <FontAwesomeIcon icon={faWeightScale} className="mr-2 ml-5" />
            <span className="flex-1 text-center">
              My Daily Weighing
            </span>
          </button>
        </div>
      </div>
      {/* i would like to add a floating pointer to the lifestyle button, however, if weighing is not done today, then the pointer should not show up */}

      <div className="flex w-full items-center mb-3 mr-5">
        <div className="w-1/4"
            onClick={handleLifestyleClicked}>
          {(weighedToday && !lifestyleCompletedToday) && (
            <FloatingPointer onClick={handleLifestyleClicked} />
          )}
          {(!weighedToday ||  !!lifestyleCompletedToday) && (
            <FontAwesomeIcon
              icon={lifestyleCompletedToday ? faCheckSquare : faSquare}
              className={`w-full ${
                lifestyleCompletedToday ? "text-green-500" : "text-gray-400"
              }`}
              size="lg"
            />
          )}
        </div>
        <div className="w-3/4">
          <button
            onClick={handleLifestyleClicked}
            className={`w-full py-3 rounded-lg transition-all duration-300 flex items-center justify-center ${
              lifestyleCompletedToday
                ? "border border-blue-500 text-blue-500 font-normal shadow-lg shadow-blue-500/30"
                : "font-bold text-white shadow-lg bg-blue-400 shadow-blue-800/35"
            }`}
          >
            <FontAwesomeIcon icon={faWalking} className="mr-2 ml-5" />
            <span className="flex-1 text-center">My Daily Lifestyle Score</span>
          </button>
        </div>
      </div>

      {/* add a floating pointer to the habits button, however, if weighing or lifestyle is not done today, then the pointer should not show up */}
      <div className="flex w-full items-center mb-3 mr-5">
        <div className="w-1/4 flex items-center justify-center" onClick={handleAssessHabits}>
          {(weighedToday && lifestyleCompletedToday && !habitAssessedToday) && (
            <FloatingPointer onClick={handleAssessHabits} />
          )}
          {(!(weighedToday && lifestyleCompletedToday) || !!habitAssessedToday) && (
            <FontAwesomeIcon
              icon={habitAssessedToday ? faCheckSquare : faSquare}
              className={`w-full ${habitAssessedToday ? "text-green-500" : "text-gray-400"}`}
              size="lg"
            />
          )}
        </div>
        <div className="w-3/4">
          <button
            onClick={handleAssessHabits}
            className={`w-full py-3 rounded-lg transition-all duration-300 flex items-center justify-center ${
              habitAssessedToday
                ? "border border-blue-500 text-blue-500 font-normal shadow-lg shadow-blue-500/30"
                : "font-bold text-white shadow-lg bg-blue-400 shadow-blue-800/35"
            }`}
          >
            <FontAwesomeIcon icon={faBook} className="mr-2 ml-5" />
            <span className="flex-1 text-center">My Daily Habits</span>
          </button>
        </div>
      </div>

      {/*<div className="flex w-full items-center mb-3 mr-5">
        <div className="w-1/4"></div>
        <div className="w-3/4">
          <button
            onClick={handleMoreClicked}
            className="w-full px-6 py-3 rounded-lg transition-all duration-300 font-bold text-white shadow-lg bg-blue-400 shadow-blue-800/35"
          >
            More...
          </button>
        </div>
        </div>*/}
      <DailyMore promptForNotificationPermission={promptForNotificationPermission} />
      {showModal && (
        <div 
          className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ${isClosing ? 'opacity-0' : 'opacity-100'}`}
          onClick={closeModal}
        >
          <div 
            className={`bg-white p-6 rounded-lg shadow-xl relative transition-all duration-300 ${isClosing ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}`}
            onClick={(e) => e.stopPropagation()}
          >
            <p className="text-lg font-semibold text-center whitespace-pre-line">
              {modalMessage}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DailyMission;