import React, { useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { currentQuestionState } from "../../../atom/survey/survey-atoms";
import { questionAnswerState } from "../../../atom/survey/survey-questions-atoms";
import Typewriter from "typewriter-effect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVenus, faMars } from "@fortawesome/free-solid-svg-icons";
import { addSurvey } from "../../../store/pouch/survey-pouch";
import { userState } from "../../../atom/atoms";
import { getUserObj, updateUserGender } from "../../../store/pouch/user-pouch";
import { addSurveyQuestion, iterateAllSurveyQuestionsByUserEmail } from "../../../store/pouch/survey-questions-pouch";
import useTokenCheckVx from "../../../hooks/useTokenCheckVx";
import vxInstance from "../../../api/VxApiService";

const QNum200 = () => {
  const user = useRecoilValue(userState);
  const [currentQuestion, setCurrentQuestion] = useRecoilState(currentQuestionState);
  const nicknameAnswer = useRecoilValue(questionAnswerState("100"));
  const [genderAnswer, setGenderAnswer] = useRecoilState(questionAnswerState("200"));

  const { isApiConnected, isLoading } = useTokenCheckVx();

  const canSyncWithServer = useMemo(() => isApiConnected && !isLoading, [isApiConnected, isLoading]);

  const nickname = nicknameAnswer?.surveyAnswerString || "";
  const selectedGender = genderAnswer?.surveyAnswerString || "";

  // Remove the showNextButton state as it's no longer needed
  // const [showNextButton, setShowNextButton] = useState(false);

  const handleGenderSelect = async (gender) => {
    let answerJson = {
      _id: `${user?.userEmail}-surveyQNum-200`,
      surveyQuestionNumber: "200",
      surveyAnswerString: gender,
      surveyAnswerList: [],
      userEmail: user?.userEmail,
    };
    setGenderAnswer(answerJson);

    try {
      await addSurveyQuestion(answerJson);
      await updateUserGender(user?.userEmail, gender);
      
      let userSurveyProgress = {
        currentQuestion: "300",
        isComplete: false,
        completionDateTime: null,
        earnedCoinAmount: 0,
        earnedCoinDatetime: null,
      };

      await addSurvey({ _id: user?.userEmail, ...userSurveyProgress });

      // Automatically call handleNext after selecting gender
      await handleNext();
    } catch (error) {
      console.error("Error saving survey question:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleNext = async () => {
    if (canSyncWithServer) {
      try {
        let allQuestions = await iterateAllSurveyQuestionsByUserEmail(user?.userEmail);
        const userQuestions = allQuestions.filter(q => q.userEmail === user?.userEmail);
        vxInstance.surveyClientToServerUpsert(user?.accessToken, user?.refreshToken, {surveyData: userQuestions});
        let userObj = await getUserObj(user?.userEmail);
        vxInstance.userClientToServerUpsert(user?.accessToken, user?.refreshToken, userObj);
        console.log("User and Survey data synchronized with server");
      } catch (apiError) {
        console.error("Failed to synchronize with server:", apiError);
        // We don't throw here to allow offline progression
      }


    } else {
      console.log("Offline mode: Survey data saved locally");
    }
    setCurrentQuestion("300");
  };

  const handleBack = () => {
    // Remove the setShowNextButton call
    setCurrentQuestion("100");
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return (
    <>
      <div className="flex justify-center mt-1">
        <img
          src={`${process.env.PUBLIC_URL}/chat2/05-cute-chatbot.jpg`}
          alt="healthChat"
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>
      <div className="flex justify-center mt-4">
        <div className="text-center text-xl font-thin text-blue-600">
          {selectedGender && <span>Your gender, {nickname}</span>}
          {!selectedGender && (
            <Typewriter
              options={{
                strings: [`Your gender, ${nickname}?`],
                autoStart: true,
                loop: false,
                deleteSpeed: Infinity,
                cursor: "",
              }}
            />
          )}
        </div>
      </div>

      <div className="flex justify-center mt-2 ml-1">
        <img
          src={`${process.env.PUBLIC_URL}/chat2/08-male-female-256.jpeg`}
          alt="gender"
          className="w-64 h-64 rounded-full object-cover"
        />
      </div>
      <div className="flex justify-center mt-2">
        <button
          type="button"
          onClick={() => handleGenderSelect("female")}
          className={`px-5 py-2 rounded mr-4 border-2 ${
            selectedGender === "female"
              ? "border-pink-500 text-pink-500 bg-white"
              : "bg-pink-500 text-white"
          }`}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faVenus} size="2x" />
            <span>&nbsp;Female</span>
          </div>
        </button>
        <button
          type="button"
          onClick={() => handleGenderSelect("male")}
          className={`px-4 py-2 rounded border-2 ${
            selectedGender === "male"
              ? "border-blue-500 text-blue-500 bg-white"
              : "bg-blue-500 text-white"
          }`}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faMars} size="2x" />
            <span>&nbsp;&nbsp;Male&nbsp;&nbsp;</span>
          </div>
        </button>
      </div>
      <div className="flex justify-center mt-2 ml-1">
        <button
          type="button"
          onClick={handleBack}
          className="mt-2 px-4 py-2 bg-gray-500 text-white rounded mr-2"
        >
          Back
        </button>
        {selectedGender && (
          <button
            type="button"
            onClick={handleNext}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
          >
            Next
          </button>
        )}
      </div>
    </>
  );
};

export default QNum200;
